:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 20px !important;
  z-index: 99999;
}

.slick-next {
  right: 40px !important;
}
.ant-tabs-nav-scroll {
  /* padding-left: 25px; */
}

.ant-popover {
  z-index: 9999999;
}

.ant-popover-arrow,
.ant-popover-inner {
  background: black;
}

.ant-popover-arrow {
  border-bottom-color: black !important;
  border-left-color: black !important;
}

.ant-popover {
  border-radius: 10px;
  background: transparent;
  width: 280px;
  margin-left: 2em;
}

.ant-popover-inner {
  border-radius: 10px;
}

.ant-popover-inner {
  padding: 15px;
}

.ant-popover-title {
  padding: 0;
  color: white;
  font-size: 1.4em;
  font-weight: bold;
  line-height: 22px;
  padding-bottom: 0.8em;
  border-bottom: 1px solid gray;
}

.ant-popover-inner-content {
  padding: 12px 0px;
  color: white;
}
.weeklyMealContainer > div > div {
  border-radius: 0;
}

.slick-slider {
  padding: 0 70px;
}
.foodPopup .ant-modal-content {
  width: 830px;
  border-radius: 0 !important;
}

.foodPopup .ant-modal-title {
  font-size: 1.4em;
  font-weight: 500;
}

.foodPopup .ant-modal-header {
  border-bottom: none;
}

.foodPopup .ant-modal-footer {
  padding: 21px;
  display: flex;
  justify-content: flex-end;
}

.ant-tabs-nav {
  padding: 0 8px;
}

.ant-tabs-tab {
  outline: none !important;
}

.ant-tabs-dropdown {
  z-index: 999999999999;
}

.loginContainer {
  width: 400px;
}

@media print {
  pre,
  blockquote {
    page-break-inside: avoid;
  }
}

.collapse:not(.in) {
  display: none;
}

.collapse.in {
  display: block;
}

@media (max-width: 768px) {
  .viewSelector {
    width: 100% !important;
    margin-bottom: 10px;
  }
  .viewSelector + button {
    margin-left: 4px !important;
  }
  .slick-slider {
    padding: 0 20px;
  }
  /* .mealTotals {
    display: none;
  } */
  .navbar-nav > div {
    flex-direction: column;
    padding-top: 1em;
  }

  .navbar-nav > div > a,
  .navbar-nav > div > p {
    margin: 0 !important;
    padding: 14px !important;
    border-top: 1px solid gainsboro;
  }
  .navbar-nav > div > p:first-child {
    border-top: none;
    margin-bottom: 18px !important;
  }

  .navbar-brand img {
    width: 130px;
  }
  .navbar h1 {
    display: flex;
    font-size: 1.7em;
    justify-content: space-between;
  }
  /* .ant-popover {
    display: none !important;
  } */
  .foodPopup .ant-modal-content {
    width: 360px;
  }
  .modalInputContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .modalInputContainer > div,
  .modalInputContainer > input {
    width: 140px !important;
    margin-bottom: 1em;
  }

  .leftOverContainer > div,
  .leftOverContainer > input {
    width: 100% !important;
  }
  .loginContainer {
    width: auto !important;
  }
  .navbar-links-container {
    width: 100%;
  }
}

.mealPopover .ant-popover-inner {
  background: white;
  padding: 10px;
  padding-left: 1.5em;
}

.mealPopover .ant-popover-inner-content {
  color: black;
}

.mealPopover .ant-popover-arrow {
  border-bottom-color: white !important;
  border-left-color: white !important;
}
