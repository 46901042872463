html {
  scroll-behavior: smooth;
}

body {
  font-size: 1em;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif !important;
  background-color: #f9f9f9;
  letter-spacing: 1px;
}

.eatThisLogo {
  padding-top: 40px;
  padding-bottom: 10px;
  text-align: center;
}
#eatthismuch-logo {
  width: 90px;
}
.stepBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.stepBtn button {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.6rem;
  border: 1px solid #fa52af;
  background-color: white;
  color: #fa52af;
  width: 100%;
  line-height: 24px;
  outline: none;
  cursor: not-allowed;
}
.active button {
  color: #fff;
  background-color: #fa52af;
  border-color: #e36f4b;
  cursor: pointer;
}

.textMuted {
  color: #6c757d;
}
.active span {
  color: #fa52af;
}
/* headerEnd */
/* footer */
.footer_container {
  margin-top: 30px;
  font-size: 13px;
  border-top: 2px solid #e3e3e3;
  padding-left: 50px;
  padding-right: 50px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 30px;
}
/* footerEnd */
/* step1 form */
.page-content {
  padding-bottom: 40px;
}

.signup_section_header {
  padding: 40px 5px;
}
.display-6 {
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.2;
}
.step1 {
  width: 100%;
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
}
.formHeading {
  font-size: 26px;
  font-weight: 400;
  margin-top: 50px;
  margin-bottom: 47px;
}
.form-group {
  margin-bottom: 30px;
}
label.btn-selector-primary {
  color: #010101;
  background-color: #ffffff;
  background-image: none;
  border-color: #898a8f;
}
.btn-group-toggle {
  width: 100%;
}
.btn-group-toggle .active {
  color: #fff;
  background-color: #3d84ff;
  border-color: #3d84ff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.three-segment-radio label {
  width: 33.3%;
}
.two-segment-radio label {
  width: 50%;
}
.signup_input_label {
  position: absolute;
  top: 7px;
  right: 40px;
}
.bfat_percent {
  font-size: 11px;
  color: #a9a8a9;
  display: inline-block;
  width: 32%;
  text-align: center;
}
.btn-orange {
  color: #fff;
  background-color: #fa52af;
  border-color: #e36f4b;
  width: 250px;
}
.btn-orange:hover {
  color: #fff;
  background-color: #e34b1b;
  border-color: #cf6544;
}
/* step2 */
.step2 {
  width: 100%;
  max-width: 930px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.contentHeading {
  font-size: 22px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.preset_selector_div {
  padding-top: 10px;
  padding-bottom: 10px;
}
.content_container {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 2px 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  transition: box-shadow 0.3s ease-in-out 0s;
}
.preset_selector svg {
  width: 40px;
}
.preset_selector .nav-link {
  font-size: 14px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.preset_selector .active span {
  color: white;
}
.bottom_spacer {
  padding-bottom: 20px;
}
.daily_price_limit_row {
  padding-top: 10px;
}
.daily_price_limit {
  display: inline-block;
}
/* step3 */
.step3 {
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.nutrition-summary-text {
  font-size: 24px;
  font-weight: bold;
}
.carbs-col {
  color: #fcb524;
}
.fats-col {
  color: #52c0bc;
}
.proteins-col {
  color: #976fe8;
}
.signup_back {
  padding-left: 15px;
  padding-top: 15px;
  display: inline-block;
}

label {
  color: black !important;
}
label {
  margin-right: 10px;
}

body * {
  letter-spacing: 0.3px;
}

/* single day view */
.singleDay {
  width: 100%;
  max-width: 458px;
  margin: auto;
}
.dayHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
}
.dayName span {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #fa52af;
}
.dayName h1 {
  font-size: 58px;
  line-height: 58px;
  letter-spacing: -2px;
  color: #fa52af;
  margin: 0;
}
.dayIcons svg {
  max-width: 24px;
  max-height: 24px;
  width: 100%;
  opacity: 0.6;
  margin-right: 0.5em;
}
.mealBox {
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  transition: box-shadow 0.15s ease-in-out;
}
.mealBox:hover {
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.22);
}
.mealTittle h4 {
  color: black;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.3px;
}
.mealTittle span {
  color: #76767a;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0.3px;
}
.iAteThis {
  color: #76767a;
  font-size: 14px;
  margin-right: 1em;
}
.mealImg {
  width: 50px;
  height: 50px;
  margin-right: 1em;
  border-radius: 10px;
}
/* weekly view  */
.dayHeader h2 {
  font-size: 26px;
  font-weight: 400;
  margin: 0;
}
.weeklyDay .mealBox {
  width: 25%;
}
.weeklyDay .foodTittle h4 {
  color: black;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.weeklyDay .foodTittle span {
  font-weight: 400;
  letter-spacing: 0.3px;
  color: #76767a;
  font-size: 14px;
}
.weeklyDay .mealImg {
  border-radius: 50%;
}
