.orangeColor {
  color: #fa52af;
}

.blackColor {
  color: black;
}

.headingTitle {
  letter-spacing: 0.3px;
  font-size: 30px;
}

.headContainer {
  background-color: #ede9e6;
  padding: 30px 22px 20px;
  width: 100%;
}

.datePick {
  padding: 6px 16px;
}

.datePick input {
  width: 0;
}
#ED59A0 .datePick span {
  margin-left: 0 !important;
}

.sidebar {
  background-color: #2d303c;
  padding-left: 30px;
  padding-top: 30px;
}

.sidebarItem {
  color: #fa52af !important;
  transition: all 0.2s;
  font-size: 19px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  margin: 0 10px;
}

.sidebarItem:hover {
  color: #fa52af;
}

.sidebarItem span {
  margin-right: 8px;
}

.dayContainer {
  /* background: #f9f9f9; */
  /* background-image: linear-gradient(rgb(255, 240, 236), rgb(249, 249, 249)); */
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  border-top: 3px solid #fa52af;
}

.dayHeader {
  /* padding-top: 20px;
  padding-bottom: 5px; */
}
.dayHeaderfirst {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.dayNumber {
  font-size: 63px;
  line-height: 58px;
  letter-spacing: -2px;
}

.mealBoxContainer {
  padding: 15px 20px 10px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.15s ease-in-out;
  margin-bottom: 3px;
}

.mealBoxContainer:hover {
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.22);
  z-index: 10;
}

.mealBoxContainer:hover .mealBoxIcon {
  color: black;
  opacity: 1;
}

.breakHeading {
  font-size: 20px;
  font-weight: 700;
  color: black;
}

.mealBoxIcon {
  font-size: 1.5em;
  color: #76767a;
  opacity: 0.4;
  transform: all 0.15s ease-in-out;
  cursor: pointer;
}

.totalContain {
  font-size: 1.2em;
  margin-bottom: 5px;
  font-weight: bold;
}

.alertWarn {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  font-size: 0.9em;
  padding: 2em;
  margin: auto;
  margin-top: 2em;
}

.searchBtn {
  background: white;
  color: black;
  font-weight: 500;
  width: 185px;
  border-radius: 50px;
  padding: 8px 12px;
  padding-left: 15px;
  cursor: pointer;
  font-size: 16px;
}

.foodBankSidebar {
  background: #ffffff;
  width: 290px;
  position: fixed;
  top: 10px;
  margin-left: 10px;
  height: 100%;
  height: calc(100% - 20px);
  left: -300px;
  -webkit-transition: left 300ms ease-out, box-shadow 300ms ease-out;
  transition: left 300ms ease-out, box-shadow 300ms ease-out;
  z-index: 100000;
}
.foodBankSidebarOpen {
  left: 0;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 4px 7px 0 rgba(0, 0, 0, 0.1),
    0 -1px 2px 0 rgba(0, 0, 0, 0.1);
}
.sidebarHeadings {
  font-weight: 700;
  font-size: 23px;
}

.foodSidebarItem {
  transform: border 0.15s ease-in-out;
  border: solid 2px rgba(0, 0, 0, 0);
  border-radius: 10px;

  padding: 10px;
}

.foodSidebarItem:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  border: solid 2px rgba(240, 117, 79, 0.2);
}

.foodSidebarItemimage {
  width: 35px;
  height: 35px;
  border-radius: 10px;
}

.foodSidebarItemText {
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #2d303c !important;
  display: inline;
  width: 65%;
  margin-left: 10px;
  margin-right: 10px;
}

.popoverserve {
  color: white;
}

.multiMealContainer {
  border-left: 1px solid gainsboro;
}

@media (max-width: 768px) {
  .multiMealContainer {
    border-left: none;
  }
}

.calorieBtn {
  margin-bottom: 15px;
  border: 1px solid gainsboro;
  border-radius: 5px;
  font-size: 1.3em;
}

.foodTitle h4 {
  color: black;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.foodTitle span {
  font-weight: 400;
  letter-spacing: 0.3px;
  color: #76767a;
  font-size: 14px;
}

.mealBox {
  transition: border 0.15s ease-in-out;
  border: solid 2px white;
  border-radius: 15px;
  padding: 1em;
  cursor: pointer;
}

.mealBox:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  border: solid 2px rgba(240, 117, 79, 0.2);
  position: relative;
}
.closeContainer {
  opacity: 0;
  cursor: pointer;
  display: flex;
}

.mealBox:hover .closeContainer {
  opacity: 1;
}

.mealBox:hover input {
  border-color: gray;
}

.mealBox input {
  width: 35px;
  transition: border 0.15s ease-in-out;
  border-color: white;
  padding: 1px 5px;
  text-align: center;
  margin-right: 2px;
}
